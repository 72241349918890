
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { twoDecimalNoZero } from '@/utils/validate'
import { PlantDicDetail } from '@/types/plantEstimation'

@Component
export default class PlantDic extends Vue {
  private showDialog = false
  private loading = false
  private page = 1
  private size = 10
  private total = 0
  private formInfo: PlantDicDetail = {
    plantName: '',
    plantType: '',
    areaCovered: ''
  }

  private tableData: Array<PlantDicDetail> = []
  private rulesForm = {
    plantName: [
      { required: true, message: '请选择植物名称', trigger: 'blur' }
    ],
    plantType: [
      { required: true, message: '请输入型号', trigger: 'blur' }
    ],
    areaCovered: [
      { required: true, message: '请输入占用面积', trigger: 'blur' },
      { validator: twoDecimalNoZero, trigger: ['change', 'blur'] }
    ]
  }

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.plantEstimated.selectPlantDictionaryByList,
      {
        page: this.page,
        size: this.size
      })
      .then((res) => {
        if (res) {
          this.total = res.total || 0
          this.tableData = res.list || []
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  addPlant () {
    this.showDialog = true
    Object.keys(this.formInfo).forEach((item: string) => {
      this.formInfo[item] = ''
    })
    this.$nextTick(() => {
      (this.$refs.DigForm as ElForm).clearValidate()
    })
  }

  onDelete (row: PlantDicDetail) {
    this.$confirm('确认删除【' + row.plantName + row.plantType + '】吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.plantEstimated.deleteDataPlantDictionary,
          {
            dicId: row.dicId
          })
          .then(() => {
            this.$message.success('删除成功')
            this.getData()
          })
      })
  }

  onSubmit () {
    (this.$refs.DigForm as ElForm).validate((valid) => {
      if (valid) {
        this.$axios.post(this.$apis.plantEstimated.insertPlantDictionary, this.formInfo)
          .then(() => {
            (this.$refs.DigForm as ElForm).resetFields()
            this.showDialog = false
            this.$message.success('保存成功')
            this.getData()
          })
      }
    })
  }
}
